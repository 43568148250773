@import '../../styles.scss';

.table {
  background-color: $color-white;
  th {
    background-color: $color-stoneLighter;
    font-size: 16px;
  }
  td {
    @extend .input;
    font-size: 15px;
    line-height: unset;
    text-align: center;
  }
  .input {
    font-size: 15px;
  }

  tr.even {
    background-color: $color-stoneLightest;
  }

  tr.DRAFT td:first-child {
    background-image: linear-gradient(to right, $color-draft, $color-white 10px);
  }
  tr.even.DRAFT td:first-child {
    background-image: linear-gradient(to right, $color-draft, $color-stoneLightest 10px);
  }
  tr.DRAFT td:last-child {
    background-image: linear-gradient(to left, $color-draft, $color-white 10px);
    background-repeat: no-repeat;
  }

  tr.PENDING_VERIFICATION td:first-child {
    background-image: linear-gradient(to right, $color-pending, $color-white 10px);
  }
  tr.even.PENDING_VERIFICATION td:first-child {
    background-image: linear-gradient(to right, $color-pending, $color-stoneLightest 10px);
  }
  tr.PENDING_VERIFICATION td:last-child {
    background-image: linear-gradient(to left, $color-pending, $color-white 10px);
    background-repeat: no-repeat;
  }

  tr.UPDATED td:first-child {
    background-image: linear-gradient(to right, $color-updated, $color-white 10px);
  }
  tr.even.UPDATED td:first-child {
    background-image: linear-gradient(to right, $color-updated, $color-stoneLightest 10px);
  }
  tr.UPDATED td:last-child {
    background-image: linear-gradient(to left, $color-updated, $color-white 10px);
    background-repeat: no-repeat;
  }

  tr.UPDATED_PENDING_VERIFICATION td:first-child {
    background-image: linear-gradient(to right, $color-updated-pending, $color-white 10px);
  }
  tr.even.UPDATED_PENDING_VERIFICATION td:first-child {
    background-image: linear-gradient(to right, $color-updated-pending, $color-stoneLightest 10px);
  }
  tr.UPDATED_PENDING_VERIFICATION td:last-child {
    background-image: linear-gradient(to left, $color-updated-pending, $color-white 10px);
    background-repeat: no-repeat;
  }
  @media print {
    td:first-child {
      background-image: none !important;
    }
    td:last-child {
      background-image: none !important;
    }
  }
}
// brand themeing
.table.brand {
  border-collapse: collapse;
  border-bottom: 1px solid $color-stoneLightest;
  th {
    background-color: $color-stoneLighter;
    border-right: 1px solid white;
    border-bottom: 2px solid $color-stoneLight;
  }
  td {
    border-right: 1px solid $color-stoneLight;
    min-height: 27px;
  }
  th:last-child,
  td:last-child {
    border-right: none;
  }
  @media print {
    th {
      background-color: none;
    }
  }
}
.table.brand.altRowBackground {
  tr:nth-child(even) {
    background-color: $color-stoneLightest;
  }
}
.table.spacing {
  tr {
    height: 26px;
  }
}
// common css that trumps brand
.table,
.table.brand {
  .transparent-header {
    background-color: $color-white;
    border: none;
  }
  .no-border-right {
    border-right: none;
  }
  .flex {
    display: flex;
  }
}
// https://stackoverflow.com/a/56998444
.sticky {
  /* If we use border,
  we must use table-collapse to avoid
  a slight movement of the header row */
  border-collapse: collapse;
  /* Set header to stick to the top of the container. */
  thead tr th {
    position: sticky;
    top: 0;
    // box-shadow: inset 0px 0px 0 1px $color-white;
    //fix for relative elements (the select list component) appearing over the header
    z-index: 1200;
  }
}
@media print {
  .sticky {
    border-collapse: collapse;
    thead tr th {
      position: unset;
      box-shadow: 0px;
    }
  }
  .widthAutoPrint {
    min-width: unset !important;
    width: auto !important;
    div {
      min-width: unset !important;
      width: unset !important;
    }
  }
  .antibodyColumnPrint {
    min-width: unset !important;
    width: 130px !important;
    div {
      min-width: unset !important;
      width: 130px !important;
    }
  }
}
