@import '../styles.scss';

.error-screen {
  .error-alignment {
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
