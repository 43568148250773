@import '../styles.scss';

.loginScreen {
  height: 100vh;
  background-color: #fafafa;
  .fill {
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: stretch;
  }
}
