@import '../../styles.scss';

.header {
  .padding {
    margin-left: $backgroundMarginNarrow-left;
    margin-right: $backgroundMarginNarrow-right;
  }
  .padding:last-child {
    margin-left: $backgroundMarginNarrow-left;
    margin-right: none;
  }
  .lockup {
    display: block;
  }
  .curve {
    background-image: url(../../assets/logos/logo-curve.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: -34px;
    height: 140px;
    width: 300px;
    margin: 0;
    position: relative;
    left: 0;
    top: 0;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }
  .brand-logo {
    position: absolute;
    left: 30px;
    top: 17px;
    height: 60px;
  }
  .title {
    font-family: 'Montserrat Semibold';
    font-size: 18px;
    line-height: 25px;
    color: $color-jarrah;
  }
  .title-link {
    font-family: 'Montserrat Medium';
    font-size: 15px;
    line-height: 20px;
    color: $color-darkStone;
    height: 100%;
    align-items: center;
    text-align: center;
  }
  .title-link.selected {
    color: #a4152d;
    border-bottom: 2px solid #a4152d;
    padding-top: 2px;
  }
  .notification-bubble {
    height: 27px;
    width: 31px;
    border-radius: 10px;
    background-color: $color-red;

    flex-direction: column;
    text-align: center;
    vertical-align: middle;
    color: $color-white;
    font-family: 'Montserrat Medium';
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;
  }

  .red {
    color: $color-red;
    font-weight: bold;
  }
}
