@import '../../styles.scss';

.confirm {
  .childrenPadding {
    margin-left: 24px;
    margin-right: 24px;
  }
  .red {
    color: $color-red;
  }
}
