@import '../../styles.scss';

.button-container {
  padding-left: $backgroundMarginNarrow-left;
  padding-right: $backgroundMarginNarrow-right;
  .button {
    border-radius: 15px;
    height: 45px;
    background-color: $color-red;
    cursor: pointer;
    .text {
      color: $color-white;
    }
  }
  .button-inner {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding-left: $buttonPaddingLeft;
    padding-right: $buttonPaddingRight;

    font-family: 'Montserrat Medium';
    font-size: 15px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: 0;
  }
  .button.slim {
    height: 30px;
  }
  .button:hover {
    background-color: #cd190a;
  }
  .button:active {
    background-color: #c71607;
  }
  .button.disabled {
    cursor: default;
    background-color: transparentize($color-red, 0.8);
    .text {
      color: transparentize($color-white, 0.8);
    }
  }
  .button-small {
    height: 22px;
    .button-inner {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .clay {
    background-color: $color-clay;
  }
  .clay:hover {
    background-color: #971329;
  }
  .clay:active {
    background-color: #851024;
  }
  .clay.disabled {
    cursor: default;
    background-color: transparentize($color-clay, 0.7);
  }

  .clay-outline {
    background-color: $color-white;
    border: 1px solid transparentize($color-clay, 0.7);
    .text {
      color: $color-clay;
    }
  }
  .clay-outline:hover {
    background-color: $color-white;
    border-color: $color-clay;
  }
  .clay-outline:active {
    background-color: $color-stoneLighter;
    border-color: $color-clay;
  }
  .clay-outline.disabled {
    cursor: default;
    background-color: $color-white;
    border-color: transparentize($color-clay, 0.8);
    .text {
      color: transparentize($color-clay, 0.7);
    }
  }

  .left {
    border-radius: 15px 0px 0px 15px;
  }
  .right {
    border-radius: 0px 15px 15px 0px;
  }
}
