@use "sass:math";
@import '../../styles.scss';

.dashboard-count {
  .subline {
    align-items: center;
    flex: 1;
    justify-content: center;
  }
  .line {
    flex-direction: row;
    cursor: pointer;
    @extend .marginNarrow;
    margin-top: math.div($backgroundMarginNarrow-top, 2);
    margin-bottom: math.div($backgroundMarginNarrow-bottom, 2);
  }
  .line:last-child {
    margin-bottom: 0;
  }

  .selected {
    color: $color-redActivated;
  }
}
