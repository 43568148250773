@import '../styles.scss';

.dashboard {
  .centre-row {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .table-margin {
    margin-top: $backgroundMarginNarrow-top;
  }
  .containerCard.pendingSubmission {
    border-color: $color-draft;
  }
  .containerCard.pendingVerification {
    border-color: $color-pending;
  }
  .containerCard.cardsPending {
    border-color: $color-redActivated;
  }
  .containerCard.cardsExported {
    border-color: $color-redActivated;
  }
  .containerCard.consentPending {
    border-color: $color-redActivated;
  }
  .containerCard.consentExported {
    border-color: $color-redActivated;
  }
}
