@import '../../styles.scss';

.status-label {
  .DRAFT {
    color: $color-draft;
  }

  .PENDING_VERIFICATION {
    color: $color-pending;
  }

  .VERIFIED {
    color: $color-verified;
  }

  .UPDATED {
    color: $color-updated;
  }

  .UPDATED_PENDING_VERIFICATION {
    color: $color-updated-pending;
  }
  @media print {
    .DRAFT,
    .PENDING_VERIFICATION,
    .VERIFIED,
    .UPDATED,
    .UPDATED_PENDING_VERIFICATION {
      color: black !important;
    }
  }
}
