@import '../../styles.scss';

.detail-text {
  flex-direction: row;
  align-items: flex-start;
  margin-top: 5px;
  min-height: 27px;
  .value {
    @extend .input;
    margin: 4px 0px 0px 10px;
    align-items: center;
    flex: 1;
    overflow: auto;
    max-height: 48px;
  }
  .label {
    flex-shrink: 1;
    min-width: 130px;
    margin-right: 10px;
    color: $color-darkStone;
  }
}
