.App {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  background-color: #f7f7f7;
  min-height: 100vh;
  min-width: 100%;
}
@media print {
  .App {
    background-color: #fff;
    display: block;
  }
}

div,
p {
  display: inherit;
  flex-direction: inherit;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
  height: auto;
}
