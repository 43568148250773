@use "sass:math";
@import '../../styles.scss';

.paginationButtons {
  .text {
    @extend .link;
    padding-left: math.div($backgroundMarginNarrow-left, 2);
    padding-right: math.div($backgroundMarginNarrow-right, 2);
  }
  .text.selected {
    color: $color-redActivated;
    font-weight: bolder;
    text-decoration: none;
  }
  .text.disabled {
    @extend .link-disabled;
  }
}
.paginationButtons.marginTop {
  margin-top: $backgroundMarginNarrow-top;
}
