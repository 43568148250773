@font-face {
  font-family: 'Fela Bold';
  src: local('Fela Bold'), url(./assets/fonts/Picador_Fela_Bold.woff2) format('woff2');
}
@font-face {
  font-family: 'Montserrat SemiBold';
  src: local('Montserrat SemiBold'), url(./assets/fonts/Montserrat-SemiBold.woff2) format('woff2');
}
@font-face {
  font-family: 'Montserrat Medium';
  src: local('Montserrat Medium'), url(./assets/fonts/Montserrat_Medium.woff2) format('woff2');
}
@font-face {
  font-family: 'Montserrat Light';
  src: local('Montserrat Light'), url(./assets/fonts/Montserrat_Light.woff2) format('woff2');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
