$color-red: #e42313;
$color-redActivated: #c71607;
$color-redInnerShadow: #ff6558;
$color-facebook: #5165aa;
$color-facebookActivated: #2f3f78;
$color-facebookInnerShadow: #7680af;
$color-black: #000000;
$color-white: #ffffff;
$color-green: #34d332;
$color-grey: #6e6e70;
$color-lightGrey: #696969;
$color-charcoal: #3d3838;
$color-darkStone: #6e6969;
$color-clay: #a4152d;
$color-clayLight: #bf5b6c;
$color-clayLighter: #dba1ab;
$color-clayLightest: #edd0d5;
$color-jarrah: #5e0024;
$color-jarrahLight: #8e4d66;
$color-jarrahLighter: #be99a7;
$color-jarrahLightest: #dfccd3;
$color-milkyWay: #0d1d53;
$color-milkyWayLight: #566187;
$color-milkyWayLighter: #9ea4ba;
$color-milkyWayLightest: #cfd2dd;
$color-stone: #d1d1d1;
$color-stoneLight: #dfdfdf;
$color-stoneLighter: #ededed;
$color-stoneLightest: #f6f6f6;
$color-background: #f6f6f6;
$color-desert: #ff8300;
$color-desertLight: #ffa84d;
$color-desertLighter: #ffcd99;
$color-desertLightest: #ffe6cc;
$color-sunshine: #ffd040;
$color-sunshineLight: #ffde79;
$color-sunshineLighter: #ffecb2;
$color-sunshineLightest: #fff6d9;

$color-draft: #990000;
$color-pending: #ff6600;
$color-verified: #006600;
$color-updated: #0d1d53; //milkyWay
$color-updated-pending: #00cc99;

$color-disabled: rgba(239, 239, 239, 0.3);

// also in Layouts.ts
$backgroundMarginLarge-left: 150px;
$backgroundMarginLarge-right: 150px;
$backgroundMarginLarge-top: 48px;
$backgroundMarginLarge-bottom: 48px;

$backgroundMarginNormal-left: 20px;
$backgroundMarginNormal-right: 20px;
$backgroundMarginNormal-top: 26.67px;
$backgroundMarginNormal-bottom: 26.67px;

$backgroundMarginNarrow-left: 13.33px;
$backgroundMarginNarrow-right: 13.33px;
$backgroundMarginNarrow-top: 13.33px;
$backgroundMarginNarrow-bottom: 13.33px;

$buttonPaddingLeft: 35px;
$buttonPaddingRight: 35px;

$inputHeight: 27px;

.marginLarge {
  margin-right: $backgroundMarginLarge-right;
  margin-left: $backgroundMarginLarge-left;
}
.marginNormal {
  margin-top: $backgroundMarginNormal-top;
  margin-right: $backgroundMarginNormal-right;
  margin-bottom: $backgroundMarginNormal-bottom;
  margin-left: $backgroundMarginNormal-left;
}
.marginNormalTop {
  margin-top: $backgroundMarginNormal-top;
}
.marginNarrow {
  margin-top: $backgroundMarginNarrow-top;
  margin-right: $backgroundMarginNarrow-right;
  margin-bottom: $backgroundMarginNarrow-bottom;
  margin-left: $backgroundMarginNarrow-left;
}
.marginNarrowTop {
  margin-top: $backgroundMarginNarrow-top;
}
.paddingNormal {
  padding-top: $backgroundMarginNormal-top;
  padding-right: $backgroundMarginNormal-right;
  padding-bottom: $backgroundMarginNormal-bottom;
  padding-left: $backgroundMarginNormal-left;
}
.paddingNarrow {
  padding-top: $backgroundMarginNarrow-top;
  padding-right: $backgroundMarginNarrow-right;
  padding-bottom: $backgroundMarginNarrow-bottom;
  padding-left: $backgroundMarginNarrow-left;
}
.paddingNarrowTop {
  padding-top: $backgroundMarginNarrow-top;
}
.heading1 {
  font-family: 'Fela Bold';
  font-size: 45px;
  line-height: 50px;
  color: $color-charcoal;
}
.heading2 {
  font-family: 'Fela Bold';
  font-size: 32px;
  line-height: 37px;
  color: $color-charcoal;
}
.heading3 {
  font-family: 'Fela Bold';
  font-size: 24px;
  line-height: 29px;
  color: $color-charcoal;
}
.heading4 {
  font-family: 'Fela Bold';
  font-size: 18px;
  line-height: 25px;
  color: $color-charcoal;
}
.heading5 {
  font-family: 'Fela Bold';
  font-size: 16px;
  line-height: 21px;
  color: $color-charcoal;
}
.preHeader {
  font-family: 'Fela Bold';
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 1;
  color: $color-charcoal;
  text-transform: 'uppercase';
}
.subheading {
  font-family: 'Montserrat Medium';
  font-size: 16px;
  line-height: 27px;
  color: $color-charcoal;
}
.paragraph {
  font-family: 'Montserrat Light';
  font-size: 14px;
  line-height: 20px;
  color: $color-charcoal;
}

.version {
  font-family: 'Montserrat Light';
  font-size: 10px;
  color: $color-grey;
  margin-left: 5px;
  margin-bottom: 4px;
}

.input {
  font-family: Verdana, sans-serif;
  font-size: 15px;
  line-height: 19px;
  color: $color-black;
}
.label {
  font-family: 'Montserrat Medium';
  font-size: 16px;
  color: $color-darkStone;
}

.link {
  font-family: 'Montserrat Medium';
  font-size: 15px;
  color: $color-clay;
  text-decoration: underline;
  cursor: pointer;
}
.link-disabled {
  color: $color-clayLighter;
  text-decoration: none;
  cursor: unset;
}
.link-text {
  @extend .link;
  font-size: 16px;
}

.shadow {
  box-shadow: 0px 2px transparentize(#6e6969, 0.92);
}

.background {
  background-color: #f7f7f7;
}
@media print {
  .background {
    background-color: #fff;
  }
}

.divider {
  background-color: $color-clayLightest;
  min-height: 1px;
  min-width: 1px;
}
@media print {
  .divider {
    background-color: grey;
  }
}

.testTab {
  @extend .subheading;
  height: 35px;
}
.testTab.react-tabs__tab:focus {
  box-shadow: unset;
  border-color: #aaa;
}

.testTab.bold {
  height: 35px;
  font-weight: 700;
}

.testTab.attention {
  // background-color: $color-draft;
  border-radius: 5px 5px 0 0;
  background-image: linear-gradient(to top, $color-draft, #ffffff 10px);
  color: $color-draft;
}
.testTab.attention:not(.react-tabs__tab--selected) {
  border-top-color: $color-white;
}

.printShow {
  display: none;
}
@media print {
  .printHide {
    display: none;
  }
  .printShow {
    display: block;
  }
  .printNoScroll {
    max-height: none !important;
    overflow: visible !important;
  }
  .printBreak {
    display: block;
    page-break-before: always;
    page-break-inside: avoid;
  }
  .printNoBreak {
    display: block;
    page-break-inside: avoid;
  }
  .printNoHeight {
    display: block;
    padding-top: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@page/size
  @page {
    size: landscape;
  }
}
