@import '../styles.scss';

.search {
  .centre-text {
    text-align: center;
  }
  .centre-row {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .table-margin {
    margin-top: $backgroundMarginNarrow-top;
  }
  .search-type-container {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: $backgroundMarginNarrow-bottom;
  }
  .search-filters-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .advanced-search-filters-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .createNewLabel {
    margin-right: $backgroundMarginNormal-right;
  }

  .radio-input {
    .label-container {
      .label {
        @extend .heading3;
      }
    }
  }

  .search-warning {
    font-weight: bold;
    color: $color-desert;
  }
}
