@import '../../styles.scss';

.genotype-table {
  table {
    margin-left: 6px;
    margin-right: 20px;
  }
  .label {
    flex-shrink: 1;
    min-width: 130px;
    margin-right: 10px;
    color: $color-darkStone;
  }
}
@media print {
  @media (orientation: portrait) {
    .genotype-table.t2 {
      flex-direction: column !important;
    }
  }
}
