@import '../../styles.scss';

.donorIdTable {
  th {
    text-align: left;
  }
  td.red {
    color: $color-red;
  }
}
