@import '../../../styles.scss';

.consent {
  display: flex;
  .row {
    flex-direction: row;
    display: flex;
  }
  .column {
    flex-direction: column;
    display: flex;
  }
  .radio-row {
    align-items: flex-end;
  }
  .row:not(:first-child) {
    margin-top: $backgroundMarginNarrow-top;
  }
}
