@import '../../styles.scss';

.date-input {
  flex-shrink: 1;
  min-height: $inputHeight;
  .input {
    @extend .input;
    flex-shrink: 1;
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    border-style: none;
    outline: none;
    text-align: center;
  }
  .input.noPadding {
    padding-left: 0px;
    padding-right: 0px;
  }
}
