@import '../../styles.scss';

.containerCard {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: $color-white;
  border-radius: 0px;
  border-style: solid;
  border-color: $color-clayLightest;
  border-width: 1px;
  padding-left: $backgroundMarginNormal-left;
  padding-right: $backgroundMarginNormal-right;
  padding-top: $backgroundMarginNormal-top;
  padding-bottom: $backgroundMarginNormal-bottom;
  margin-bottom: 0px;

  .title {
    @extend .heading2;
  }
}
.containerCard.personViewCard {
  padding-top: 10px;
}
@media print {
  .containerCard {
    border-width: 0px;
    padding: 0px;
    background-color: #fff;
  }
}
