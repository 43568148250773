@import '../../../styles.scss';

.person-bar {
  .action-bar {
    flex-grow: 1;
    margin-left: $backgroundMarginNormal-left;
    margin-right: $backgroundMarginNormal-right;
    align-items: center;
    min-height: 55px;
  }
  @media print {
    .action-bar {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  .label-container {
    flex-direction: column;
    align-items: flex-start;
    min-height: 55px;
  }
}
