@import '../../styles.scss';

.radio-input {
  .label-container {
    .label {
      @extend .subheading;
    }
  }
  .container {
    border-style: 'none';
    align-items: 'flex-end';
    .label {
      @extend .subheading;
    }
  }
  .instruction-text {
    @extend .paragraph;
  }
}
