@import '../../styles.scss';

.select-input {
  .select {
    flex-grow: 1;
    border-style: none;
    outline: none;
  }
  .select__control {
    flex-grow: 1;
    border: 0px none;
  }
  .select__menu {
    flex-direction: column;
  }
  .select__placeholder {
    @extend .input;
    color: $color-black;
  }
  .select__single-value {
    @extend .input;
    color: $color-black;
  }
  .select__value {
    @extend .input;
  }
  .select__value-container,
  .select__multi-value__label {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .select__value-container div,
  .select__multi-value {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .select__input {
    @extend .input;
    color: $color-black;
    input {
      @extend .input;
      color: $color-black;
    }
  }
  .select__option {
    @extend .input;
    color: $color-black;
  }
  .select__option--is-disabled {
    height: 0;
    padding: 0;
    overflow: hidden;
    color: $color-lightGrey;
  }
  .select__indicators,
  .select__indicator {
    padding: 0px;
    padding-right: 5px;
  }
  .select__menu-portal {
    padding: 0px;
    z-index: 1300;
  }

  .select--is-disabled {
    background-color: $color-disabled;
  }
}
