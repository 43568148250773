@import '../../styles.scss';

.text-input {
  .container {
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: white;
    border-radius: 3px;
    border: 1px;
    margin: 1px;
    border-style: solid;
    flex-direction: row;
    align-items: center;
    max-width: 100%;

    max-height: $inputHeight;
  }

  .input {
    @extend .input;
    padding-left: 8px;
    padding-right: 8px;
    border-style: none;
    outline: none;
    box-sizing: border-box;
    max-width: 100%;
    flex: 1;
  }
  input:disabled {
    background-color: transparent;
  }
}
