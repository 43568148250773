@import '../../styles.scss';

.consent-label {
  .PENDING {
    color: $color-draft;
  }
  @media print {
    .PENDING {
      color: black !important;
    }
  }
}
