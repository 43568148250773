@import '../../styles.scss';

.person-screen {
}
.person-details {
  @extend .marginNormal;
}
@media print {
  .person-details {
    margin: 0px;
  }
}
