@import '../../styles.scss';

.input-control {
  margin-top: 5px;
  min-height: $inputHeight;
  display: flex;
  > div {
    min-height: $inputHeight;
  }
  .container {
    background-color: white;
    border-radius: 3px;
    border: 1px;
    margin: 1px;
    border-style: solid;
    flex-direction: row;
    align-items: center;
    flex: 1;
  }
  .container.disabled {
    background-color: $color-disabled;
    border-color: #cccccc;
  }
  .container.validation-null {
    border-color: $color-clayLightest;
    border-style: solid;
  }
  .container.validation-failed {
    border-color: $color-red;
    border-style: solid;
  }
  .container:focus-within {
    border-width: 2px;
    margin: 0px;
  }
  .label-container {
    flex-shrink: 1;
    min-width: 130px;
    margin-right: 10px;
  }
  .label {
    @extend .subheading;
  }
  .instruction-text {
    @extend .paragraph;
  }
  .icon {
    align-items: center;
    justify-content: center;
    width: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
}
