@import '../../styles.scss';

.text-area {
  margin-top: $backgroundMarginNormal-top;
  .container {
    background-color: white;
    border-radius: 5px;
    border: 1px;
    margin: 1px;
    border-style: solid;
    flex-direction: row;
    align-items: center;
    min-height: 50px;
  }
  .container svg {
    margin-right: 10px;
  }
  @media print {
    .container {
      border: 0px;
    }
  }
  .input {
    @extend .input;
    flex-grow: 1;
    padding-left: 7px;
    padding-right: 7px;
    margin: 1px;
    border-style: none;
    min-height: 46px;
    outline: none;
    // https://stackoverflow.com/questions/10943579/adjust-the-textarea-height-and-width-to-fit-in-td-of-a-table
    border: none;
    width: 100%;
    -webkit-box-sizing: border-box; /* <=iOS4, <= Android  2.3 */
    -moz-box-sizing: border-box; /* FF1+ */
    box-sizing: border-box; /* Chrome, IE8, Opera, Safari 5.1*/
  }
  .input:disabled {
    background-color: rgba(239, 239, 239, 0.3);
  }
  @media print {
    .input {
      padding: 0px;
      resize: none !important;
    }
    .input:disabled {
      background-color: white;
    }
  }
  .instruction-text {
    @extend .paragraph;
  }
  .icon {
    align-items: center;
    justify-content: center;
    width: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
}
